import React from "react";

import { Box, Link } from "@mui/material";

import styles from "@diehard/styles/intro.module.css";


const IntroPage = () => {
    
  return (
    <div className={styles.container}>
      <div
        className="w-screen h-screen overflow-y-hidden"
      >
        <Box
          className="w-screen h-screen object-cover blur-md scale-110 fixed"
          component="img"
          src="https://ovtxysbssuvneuxqphcv.supabase.co/storage/v1/object/public/assets/promo/introvid.gif" />
        <div className={styles.content}>
          <div className={styles.title}>
            <Box 
                component="img"
                src="https://ovtxysbssuvneuxqphcv.supabase.co/storage/v1/object/public/assets/general/biglogo.png"
                className="h-[120px] mb-5 md:mb-10"
              />
          </div>
          <div className="text-center text-[19px] md:text-[64px] text-white font-archivo font-bold">
            ARE YOU A <i>FAN</i> OR <span className="inline-block">AN <i>ARTIST</i> ?</span>
          </div>
          <div className={styles.buttonContainer}>
            <Link href="/landing/fans">
              <button className={styles.button}>FAN</button>
            </Link>
            <div className={styles.line}></div>
            <Link href="/landing/artists">
              <button className={styles.button}>ARTIST</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroPage;